.header {
    padding: 0;
    height: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #FFF!important;
    .header-img-box{
      height: 30px;
      width: 30px;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        height: 100%;
        width: 100%;
        border-radius: 50%;
        cursor: pointer;
      }
    }
    .header-nickname{
      margin-right: 20px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
    }
    .ant-menu {
        background: transparent;
        color: @white;
        .ant-menu-item {
            &:hover {
                color: @white;
            }
        }
    }
}
.header__trigger {
    color: @white;
}
