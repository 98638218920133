.breadcrumb-box{
  height: 100px;
  background: #FFF;
  margin-bottom: 15px;
  margin-top: 5px;
  margin-left: -12px;
  margin-right: -12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 32px;
  .line1{
    height: 22px;
    color: #595959;
    line-height: 22px;
    font-size: 14px;
  }
  .line2{
    height: 28px;
    line-height: 28px;
    font-size: 20px;
    font-weight: 600;
  }

}