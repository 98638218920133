.promote-message-page {
  background: #fff;
  padding: 0 32px;
  margin-top: 15px;
  height: calc(100vh - 210px);
   .box{
     height: 80px;
     line-height: 80px;
     border-bottom: 1px solid #dddddd;
     .copy{
       color: #2797ff;
       margin-left: 20px;
       cursor: pointer;
     }
     .text{
       font-size: 14px;
       color: rgba(0, 0, 0, 0.85);
     }
   }
   .erweima{
     position: relative;
     padding-top: 30px;

     .text{
       font-size: 14px;
       color: rgba(0, 0, 0, 0.85);
     }
     .erweima-pic{
        position: absolute;
       border-radius: 4px;
     }
     .erweima-box{
       margin-left: 90px;
       margin-top: -30px;
       height: 180px;
       width: 180px;
       position: relative;
       display: flex;
       justify-content: center;
       align-items: center;
       cursor: pointer;
       border-radius: 9px;
       box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
       background-color: #ffffff;
       :hover{
         opacity: 0.9;
         background-color: #1890ff;
         &.mask .hover-test{
           display: block;
         }
       }
       #qrCode{
         padding: 10px;
         cursor:pointer;
       }
       .mask{
         position: absolute;
         height: 100%;
         width: 100%;
         color: #FFF;
         justify-content: center;
         align-items: center;
         display: flex;
         .hover-test{
           display: none;
         }
       }
     }
   }

}
