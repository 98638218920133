.login-home-page {
    height: 100vh;
    width: 100vw;
    background: url('../../../assets/login/houtai-beijing@3x.png') no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .login-wrap {
        width: 470px;
        height: 480px;
        background: #fff;
        z-index: 1000;
        .color-block {
            margin-top: 50px;
            height: 5px;
            display: flex;
            position: relative;
            justify-content: center;
            span {
                display: inline-block;
                height: 5px;
                width: 72px;
                background-image: linear-gradient(to right, #61c9fd, #31a2fe 142%);
            }
        }
        .title-wrap {
            margin-top: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 42px;
            line-height: 42px;
            .logo {
                width: 40px;
                height: 20px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .title {
                margin-left: 15px;
                font-size: 30px;
                font-weight: 500;
                color: dodgerblue;
                user-select: none;
            }
        }
        .login-form-wrap {
            padding-left: 51px;
        }
        .ant-btn-primary {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 368px;
            margin-top: 30px;
            border-radius: 2px;
            background-color: #1890ff;
            border: none;
            span {
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: #fff;
            }
        }
    }
    .btn-wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        .text {
            width: 368px;
            height: 40px;
            line-height: 40px;
            border-radius: 2px;
            background-color: #1890ff;
            text-align: center;
            margin-top: 30px;
            color: #fff;
        }
    }
    .footer {
        color: #fff;
        width: 100%;
        position: absolute;
        bottom: 48px;
        text-align: center;
    }
}
