.gutter-example.member {
    .selectTime {
        position: relative;
        line-height: 32px;
        font-size: 14px;
        margin-right: 9px;
        height: 57px;
    }
    .selectedResult {
        width: 450px;
        position: absolute;
        color: rgb(62, 175, 52);
    }
    .form {
        margin: 32px 0 35px;
    }
    .flexBetween {
        display: flex;
        justify-content: space-between;
    }
    .nicknameInput {
        display: flex;
        align-items: center;
        height: 32px;
        margin-left: 86px;
    }
    .datePick_container {
        width: 363px;
        position: relative;
        display: flex;
        height: 32px;
        .datePick {
            margin-right: 24px;
            cursor: pointer;
            color: rgba(0, 0, 0, 0.65);
            &:hover {
                color: #2797ff;
            }
        }
        .datePick.cur {
            color: #2797ff;
        }
    }
    .desc {
        margin: 5px 0 0;
        padding: 4px 3px 3px 13px;
        border-radius: 2px;
        border: solid 1px #bae7ff;
        background-color: #e6f7ff;
        font-family: PingFangSC;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #595959;
    }

    .gutter-box .ant-card {
        padding: 0 32px;
        .ant-card-body {
            padding: 0;
        }
    }
}
